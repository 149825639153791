import { ActivatedRoute, Router } from '@angular/router';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  ACCOUNTTYPE,
  APP_ZONES
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { catchError, map, take } from 'rxjs';
import { UpdateBeneficiary } from '../../../actions/beneficiary.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import {
  AddBeneficiaryRequest,
  UpdateBeneficiaryRequest,
} from '../../../services/account-service/account.type';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function addEditBeneficiary(
  store: Store,
  router: Router,
  accountService: AccountService,
  route: ActivatedRoute,
  alertHandlerService: AlertHandlerService,
) {
  const projectSettingsData = store.selectSnapshot(ProjectSettingsState.getProjectSettings);
  const roleData = store.selectSnapshot(RoleState.getRole);
  route?.queryParams.pipe(take(1)).subscribe((params) => {
    const beneficiaryFlowParam = params['flow'];
    if (beneficiaryFlowParam === OtpParam.AddBeneficiary) {
      const formActionStateData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_BENEFICIARY),
      );
      const formData = formActionStateData?.formData as AddBeneficiaryRequest;
      if (projectSettingsData?.smsAuthenticationEnabled) {
        if (projectSettingsData.smsAuthenticationExclusion?.['addBeneficiary']?.includes(roleData?.role)) {
          // previous scenario
          callAddBeneficiaryAPI(accountService, router, formData, alertHandlerService);
        } else {
          // new OTP page redirection
          router.navigateByUrl(
            `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/authentication-sms?otp=${OtpParam.AddBeneficiary}`,
          );
        }
      } else {
        // previous scenario
        callAddBeneficiaryAPI(accountService, router, formData, alertHandlerService);
      }
    } else {
      const formActionStateData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY),
      );
      const formData = formActionStateData?.formData?.payload as UpdateBeneficiaryRequest;
      if (formData?.id) {
        if (projectSettingsData?.smsAuthenticationEnabled) {
          if (projectSettingsData.smsAuthenticationExclusion?.['editBeneficiary']?.includes(roleData?.role)) {
            // previous scenario
            store.dispatch(new UpdateBeneficiary(formData, formData.id));
          } else {
            // OTP page redirection
            router.navigateByUrl(
              `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/authentication-sms?otp=${
                OtpParam.EditBeneficiary
              }`,
            );
          }
        } else {
          // previous scenario
          store.dispatch(new UpdateBeneficiary(formData, formData.id));
        }
      }
    }
  });
}

function callAddBeneficiaryAPI(
  accountService: AccountService,
  router: Router,
  formData: AddBeneficiaryRequest,
  alertHandlerService: AlertHandlerService,
) {
  accountService
    .addBeneficiary(formData)
    .pipe(
      take(1),
      map(() => {
        router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/select-beneficiary`);
        alertHandlerService.showAlertFn(
          'success',
          'The beneficiary has been successfully added.',
          'Beneficiary created!',
        );
      }),
      catchError(() => {
        const endRoute =
          formData.accountType === ACCOUNTTYPE.PERSONAL
            ? 'add-beneficiary-personal'
            : 'add-beneficiary-business';
        alertHandlerService.showAlertFn(
          'error',
          'There was an error creating the beneficiary. Try again.',
          'Failed to create beneficiary!',
        );
        return router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/${endRoute}`);
      }),
    )
    .subscribe();
}
