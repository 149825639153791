import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from '@apollo/client/utilities';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AlertTypes,
  APP_ZONES,
  BaseWidgetProperties,
  ExitPortListType,
  getRevenirCountryObject,
  ReceiptStatus,
  RevenirSubjectStatusResponse,
  RevenirTransactionItemType,
  RevenirTransactionType,
  RevenirTripType,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import moment from 'moment';
import { catchError, map, tap } from 'rxjs';
import { SetFormActionWithId } from '../actions/form-submission.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import {
  ClearArchivedRevenirTrip,
  CreateRevenirSubjectToken,
  CreateRevenirTrip,
  GetBoardingPass,
  GetPortList,
  GetRevenirArchivedTrips,
  GetRevenirBarcodeImage,
  GetRevenirSubjectStatus,
  GetRevenirTransactionDetails,
  GetRevenirTransactionItems,
  GetRevenirTransactionList,
  GetRevenirTransactionReceipt,
  GetRevenirTripDetails,
  GetRevenirTripList,
  MakeTripAsArchiveTrip,
  ResetSelectedTripDetails,
  SetArchivedRevenirTrip,
  SetRevenirReviewTransactionReceipt,
  SetRevenirTrip,
  UploadBoardingPass,
  UploadBoardingPassWithId,
  UploadPassport,
  UploadRevenirTransactionReceipt,
} from '../actions/revenir.action';
import { RevenirService } from '../services/revenir-service/revenir.service';
import { redirectToPage } from '../utils/cta-button-actions/cta-button-actions.utils';
import { createRevenirTripPayload } from '../utils/revenir-utils';
import { ApiActions, UpdateApiIsLoadingAction } from './api-loading.state';

export interface RevenirStateModel {
  transactionList: RevenirTransactionType[];
  transactionDetails: RevenirTransactionType;
  transactionItems: RevenirTransactionItemType[];
  tripList: RevenirTripType[];
  archivedTrips: RevenirTripType[];
  selectedTripDetails: RevenirTripType | undefined;
  archivedTripDetails: RevenirTripType | undefined;
  selectedTransactionReceiptUrl: string;
  selectedTransactionReceiptType: string;
  barcodeImage: string;
  exitPointPorts: ExitPortListType;
  boardingPassPreviewUrl: string;
  boardingPassPreviewType: string;
  kyc_status: string;
}

@State<RevenirStateModel>({
  name: 'revenirState',
  defaults: {
    transactionList: [],
    transactionDetails: {} as RevenirTransactionType,
    transactionItems: [],
    tripList: [],
    archivedTrips: [],
    selectedTripDetails: undefined,
    archivedTripDetails: undefined,
    selectedTransactionReceiptUrl: '',
    selectedTransactionReceiptType: '',
    barcodeImage: '',
    exitPointPorts: {},
    boardingPassPreviewUrl: '',
    boardingPassPreviewType: '',
    kyc_status: '',
  },
})
@Injectable()
export class RevenirState {
  constructor(
    private revenirService: RevenirService,
    private router: Router,
    private alertHandlerService: AlertHandlerService,
  ) {}

  @Selector()
  static getRevenirTransactionList(state: RevenirStateModel) {
    return state.transactionList;
  }

  @Selector()
  static getRevenirTransactionItemList(state: RevenirStateModel) {
    return state.transactionItems;
  }

  @Selector()
  static getRevenirStateDetails(state: RevenirStateModel) {
    return state;
  }

  @Selector()
  static getRevenirTripList(state: RevenirStateModel) {
    return state.tripList;
  }

  @Selector()
  static getRevenirArchivedTrips(state: RevenirStateModel) {
    return state.archivedTrips;
  }

  @Selector()
  static getSelectedRevenirTripDetails(state: RevenirStateModel) {
    return state.selectedTripDetails;
  }

  @Selector()
  static getSelectedRevenirTransactionReceiptDetails(state: RevenirStateModel) {
    return {
      url: state.selectedTransactionReceiptUrl,
      type: state.selectedTransactionReceiptType,
    };
  }

  @Selector()
  static getSelectedRevenirTransactionDetails(state: RevenirStateModel) {
    return state.transactionDetails;
  }

  @Selector()
  static getArchivedRevenirTripDetails(state: RevenirStateModel) {
    return state.archivedTripDetails;
  }

  @Selector()
  static getBarcodeImage(state: RevenirStateModel) {
    return state.barcodeImage;
  }

  @Action(GetRevenirTripDetails)
  fetchRevenirTripDetails(ctx: StateContext<RevenirStateModel>, action: GetRevenirTripDetails) {
    const { getState, setState } = ctx;

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTripDetails, 'GetRevenirTripDetails');

    return this.revenirService.getTripDetails(action.tripId).pipe(
      tap((revenirTripDetails) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripDetails, 'GetRevenirTripDetails');
        setState({
          ...getState(),
          selectedTripDetails: {
            ...(getState().selectedTripDetails as RevenirTripType),
            ...revenirTripDetails,
            countryName: this.getCountryName(getState().selectedTripDetails?.country as string),
          },
          transactionList: revenirTripDetails?.transactions || [],
        });
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripDetails, 'GetRevenirTripDetails');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTransactionList)
  fetchRevenirTransactionList(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionList) {
    const { getState, setState } = ctx;

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTripTransactions, 'GetRevenirTransactionList');

    return this.revenirService.getTripDetails(action.tripId).pipe(
      tap((revenirTripDetails) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripTransactions, 'GetRevenirTransactionList');
        setState({
          ...getState(),
          transactionList: revenirTripDetails?.transactions || [],
        });
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripTransactions, 'GetRevenirTransactionList');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTripList)
  fetchRevenirTripList(ctx: StateContext<RevenirStateModel>, action: GetRevenirTripList) {
    const { getState, setState } = ctx;

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTripList, 'GetRevenirTripList');

    return this.revenirService.getRevenirTrips(action?.param).pipe(
      tap((revenirRevenirTripType) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripList, 'GetRevenirTripList');
        setState({
          ...getState(),
          tripList: revenirRevenirTripType.trips || [],
        });
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripList, 'GetRevenirTripList');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirArchivedTrips)
  fetchRevenirArchivedTripList(ctx: StateContext<RevenirStateModel>, action: GetRevenirArchivedTrips) {
    const { getState, setState } = ctx;

    this.addLoaderInAPI(ctx, ApiActions.getRevenirArchivedTrips, 'GetRevenirArchivedTrips');

    return this.revenirService.getRevenirTrips(action?.param).pipe(
      tap((revenirArchivedTrips) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirArchivedTrips, 'GetRevenirArchivedTrips');
        setState({
          ...getState(),
          archivedTrips: revenirArchivedTrips.trips || [],
        });
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirArchivedTrips, 'GetRevenirArchivedTrips');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTransactionDetails)
  fetchRevenirTransactionDetails(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionDetails) {
    const { getState, setState } = ctx;

    // resetting the transaction's details before calling API
    setState({
      ...getState(),
      transactionDetails: {} as RevenirTransactionType,
    });

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTransactionDetails, 'GetRevenirTransactionDetails');

    return this.revenirService.getTransactionDetails(action.transactionId).pipe(
      tap((transactionDetails) => {
        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionDetails,
          'GetRevenirTransactionDetails',
        );
        ctx.dispatch(new GetRevenirTransactionItems(action.transactionId));
        ctx.dispatch(new GetRevenirTransactionReceipt(action.transactionId));

        const receiptStatus = transactionDetails.transaction.receipt?.status;
        const receiptError = transactionDetails.transaction.receipt?.error;
        let alertType: AlertTypes;

        switch (receiptStatus) {
          case ReceiptStatus.Failed:
          case ReceiptStatus.ImproperReceipt:
          case ReceiptStatus.IrrelevantReceipt:
          case ReceiptStatus.LineItemTotalMismatch:
          case ReceiptStatus.TransactionAmountReceiptAmountMismatch:
            alertType = AlertTypes.ERROR;
            break;
          case ReceiptStatus.UnderReview:
            alertType = AlertTypes.WARNING;
            break;
          default:
            alertType = AlertTypes.INFO;
        }
        let receiptMessage = `Receipt Status: ${receiptStatus}`;
        if (receiptError) {
          receiptMessage += ` | Error: ${receiptError}`;
        }

        this.alertHandlerService.showAlertFn(alertType, receiptMessage);

        setState({
          ...getState(),
          transactionDetails: transactionDetails?.transaction,
        });
        ctx.dispatch(
          new SetFormActionWithId(
            {
              type: '',
              formData: {
                'total-amount': transactionDetails?.transaction?.total_amount,
                'total-vat-amount': transactionDetails?.transaction?.total_vat_amount,
                'merchant-name': transactionDetails?.transaction?.merchant_name,
                'transaction-date': moment(transactionDetails?.transaction?.transaction_date).format(
                  'DD-MM-YYYY',
                ),
              },
            },
            FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
          ),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionDetails,
          'GetRevenirTransactionDetails',
        );
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTransactionItems)
  fetchRevenirTransactionItems(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionItems) {
    const { getState, setState } = ctx;

    // resetting the transaction's items before calling API
    setState({
      ...getState(),
      transactionItems: [],
    });

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTransactionItems, 'GetRevenirTransactionItems');

    return this.revenirService.getTransactionItems(action.transactionId).pipe(
      tap((transactionItems) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTransactionItems, 'GetRevenirTransactionItems');
        setState({
          ...getState(),
          transactionItems: transactionItems?.items || [],
        });
        ctx.dispatch(
          new SetFormActionWithId(
            {
              type: '',
              formData: { transactionItems: transactionItems?.items || [] },
            },
            FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
          ),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTransactionItems, 'GetRevenirTransactionItems');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTransactionReceipt)
  fetchRevenirTransactionReceipt(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionReceipt) {
    const { getState, setState } = ctx;

    // resetting the transaction's receipt URL before calling API
    setState({
      ...getState(),
      selectedTransactionReceiptUrl: '',
      selectedTransactionReceiptType: '',
    });

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTransactionReceipt, 'GetRevenirTransactionReceipt');

    return this.revenirService.getTransactionReceipt(action.transactionId).pipe(
      tap((transactionReceipt) => {
        const url = URL.createObjectURL(transactionReceipt as Blob);

        setState({
          ...getState(),
          selectedTransactionReceiptUrl: url || '',
          selectedTransactionReceiptType: transactionReceipt.type,
        });

        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionReceipt,
          'GetRevenirTransactionReceipt',
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionReceipt,
          'GetRevenirTransactionReceipt',
        );
        throw _err;
      }),
    );
  }

  @Action(SetRevenirTrip)
  setRevenirTrip(ctx: StateContext<RevenirStateModel>, action: SetRevenirTrip) {
    ctx.patchState({
      ...ctx.getState(),
      selectedTripDetails: {
        ...ctx.getState()?.selectedTripDetails,
        ...action.selectedTripDetails,
        countryName: this.getCountryName(action.selectedTripDetails?.country),
      },
    });
  }

  @Action(ResetSelectedTripDetails)
  resetSelectedTripDetails(ctx: StateContext<RevenirStateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      selectedTripDetails: undefined,
    });
  }

  addLoaderInAPI(ctx: StateContext<RevenirStateModel>, loadingType: ApiActions, stateAction: string) {
    ctx.dispatch(new UpdateApiIsLoadingAction(loadingType, true));
    ctx.dispatch(new AddProgressBarStack({ uniqueId: stateAction }));
  }

  removeLoaderFromAPI(ctx: StateContext<RevenirStateModel>, loadingType: ApiActions, stateAction: string) {
    ctx.dispatch(new UpdateApiIsLoadingAction(loadingType, false));
    ctx.dispatch(new RemoveProgressBarStack({ uniqueId: stateAction }));
  }

  @Action(SetArchivedRevenirTrip)
  setArchivedRevenirTrip(ctx: StateContext<RevenirStateModel>, action: SetArchivedRevenirTrip) {
    ctx.patchState({
      ...ctx.getState(),
      archivedTripDetails: {
        ...ctx.getState()?.archivedTripDetails,
        ...action.archivedTripDetails,
        countryName: this.getCountryName(action.archivedTripDetails?.country),
      },
    });
  }

  @Action(ClearArchivedRevenirTrip)
  clearArchivedTrip(ctx: StateContext<RevenirStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      archivedTripDetails: undefined,
    });
  }

  @Action(MakeTripAsArchiveTrip)
  makeTripAsArchiveTrip(ctx: StateContext<RevenirStateModel>, action: MakeTripAsArchiveTrip) {
    this.addLoaderInAPI(ctx, ApiActions.makeTripAsArchiveTrip, 'MakeTripAsArchiveTrip');
    return this.revenirService.archiveTripByTripId(action.trip.trip_id ?? '').pipe(
      tap(() => {
        this.removeLoaderFromAPI(ctx, ApiActions.makeTripAsArchiveTrip, 'MakeTripAsArchiveTrip');
        const widgetProperties: BaseWidgetProperties = {
          urlToNavigate: 'archive-trip-notifications-success',
          zoneToNavigate: APP_ZONES.REVENIR_ZONE,
          textContent: '',
        };
        ctx.dispatch(new ClearArchivedRevenirTrip());
        redirectToPage(this.router, widgetProperties);
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.makeTripAsArchiveTrip, 'MakeTripAsArchiveTrip');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirBarcodeImage)
  getRevenirBarcodeImage(ctx: StateContext<RevenirStateModel>, action: GetRevenirBarcodeImage) {
    this.addLoaderInAPI(ctx, ApiActions.getRevenirBarcodeImage, 'GetRevenirBarcodeImage');
    return this.revenirService.getBarcodeImageByTripId(action.tripId ?? '').pipe(
      tap((response: Blob) => {
        const url = URL.createObjectURL(response);
        ctx.patchState({ barcodeImage: url });
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirBarcodeImage, 'GetRevenirBarcodeImage');
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirBarcodeImage, 'GetRevenirBarcodeImage');
        throw _err;
      }),
    );
  }

  @Action(GetPortList)
  getExitPointPorts(ctx: StateContext<RevenirStateModel>, action: { countryCode: string; type: string }) {
    return this.revenirService.getPortList(action.countryCode, action.type).pipe(
      map((response) => {
        ctx.patchState({
          ...ctx.getState(),
          exitPointPorts: response,
        });
      }),
      catchError((error) => {
        throw error;
      }),
    );
  }

  @Action(UploadBoardingPass)
  UploadBoardingPass(ctx: StateContext<RevenirStateModel>, action: UploadBoardingPass) {
    this.addLoaderInAPI(ctx, ApiActions.UploadBoardingPass, 'UploadBoardingPass');
    return this.revenirService.uploadBoardingPass(action.payload).pipe(
      tap((response) => {
        this.alertHandlerService.showAlertFn('success', 'Boarding pass uploaded successfully.');
        this.removeLoaderFromAPI(ctx, ApiActions.UploadBoardingPass, 'UploadBoardingPass');
        const widgetProperties: BaseWidgetProperties = {
          urlToNavigate: 'review-upload-boarding-pass',
          zoneToNavigate: APP_ZONES.REVENIR_ZONE,
          textContent: '',
        };
        ctx.dispatch(
          new SetFormActionWithId(
            {
              type: '',
              formData: { document_id: response?.document_id ?? '', trip_id: response?.trip_id },
            },
            FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
          ),
        );
        redirectToPage(this.router, widgetProperties);
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.UploadBoardingPass, 'UploadBoardingPass');
        throw _err;
      }),
    );
  }

  @Action(SetRevenirReviewTransactionReceipt)
  async SetReviewTransactionReceipt(
    ctx: StateContext<RevenirStateModel>,
    action: SetRevenirReviewTransactionReceipt,
  ) {
    const { getState, setState } = ctx;
    const blob = new Blob([action.file], { type: action.file.type });
    if (blob) {
      const url = URL.createObjectURL(blob as Blob);
      setState({
        ...getState(),
        selectedTransactionReceiptUrl: url || '',
        selectedTransactionReceiptType: blob.type,
      });
    }
  }

  @Action(UploadRevenirTransactionReceipt)
  async UploadTransactionReceipt(
    ctx: StateContext<RevenirStateModel>,
    action: UploadRevenirTransactionReceipt,
  ) {
    const { getState } = ctx;
    this.addLoaderInAPI(ctx, ApiActions.UploadRevenirTransactionReceipt, 'UploadRevenirTransactionReceipt');
    const receiptAPIRequest = await this.revenirService.uploadTransactionReceipt(
      action.transactionId,
      getState().selectedTransactionReceiptUrl,
    );
    return receiptAPIRequest.pipe(
      tap(() => {
        this.removeLoaderFromAPI(
          ctx,
          ApiActions.UploadRevenirTransactionReceipt,
          'UploadRevenirTransactionReceipt',
        );
        ctx.dispatch(new GetRevenirTransactionDetails(action.transactionId));

        const tripId = getState().selectedTripDetails?.trip_id as string;
        ctx.dispatch(new GetRevenirTransactionList(tripId));

        const widgetProperties: BaseWidgetProperties = {
          urlToNavigate: 'edit-transaction',
          zoneToNavigate: APP_ZONES.REVENIR_ZONE,
          textContent: '',
        };

        redirectToPage(this.router, widgetProperties);
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        const widgetProperties: BaseWidgetProperties = {
          urlToNavigate: 'edit-transaction',
          zoneToNavigate: APP_ZONES.REVENIR_ZONE,
          textContent: '',
        };

        redirectToPage(this.router, widgetProperties);

        this.removeLoaderFromAPI(
          ctx,
          ApiActions.UploadRevenirTransactionReceipt,
          'UploadRevenirTransactionReceipt',
        );
        throw _err;
      }),
    );
  }

  @Action(UploadBoardingPassWithId)
  UploadBoardingPassWithId(ctx: StateContext<RevenirStateModel>, action: UploadBoardingPassWithId) {
    this.addLoaderInAPI(ctx, ApiActions.uploadBoardingPassWithId, 'uploadBoardingPassWithId');
    return this.revenirService.uploadBoardingPassWithTripId(action.payload, action.tripId).pipe(
      tap(() => {
        this.alertHandlerService.showAlertFn('success', 'Boarding pass uploaded successfully.');

        this.removeLoaderFromAPI(ctx, ApiActions.uploadBoardingPassWithId, 'uploadBoardingPassWithId');
        const widgetProperties: BaseWidgetProperties = {
          urlToNavigate: 'review-upload-boarding-pass',
          zoneToNavigate: APP_ZONES.REVENIR_ZONE,
          textContent: '',
        };
        ctx.dispatch(
          new SetFormActionWithId(
            {
              type: '',
              formData: { trip_id: action.tripId },
            },
            FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
          ),
        );
        redirectToPage(this.router, widgetProperties);
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.uploadBoardingPassWithId, 'uploadBoardingPassWithId');
        throw _err;
      }),
    );
  }

  @Action(GetBoardingPass)
  getBoardingPass(ctx: StateContext<RevenirStateModel>, action: { tripId: string }) {
    const { getState, setState } = ctx;

    // resetting the boardingPassPreviewUrl URL before calling API
    setState({
      ...getState(),
      boardingPassPreviewUrl: '',
      boardingPassPreviewType: '',
    });
    this.addLoaderInAPI(ctx, ApiActions.reviewBoardingPass, 'reviewBoardingPass');
    return this.revenirService.getBoardingPass(action.tripId).pipe(
      map((response) => {
        const url = URL.createObjectURL(response as Blob);
        ctx.patchState({
          ...ctx.getState(),
          boardingPassPreviewUrl: url ?? '',
          boardingPassPreviewType: response.type ?? '',
        });
        this.removeLoaderFromAPI(ctx, ApiActions.reviewBoardingPass, 'reviewBoardingPass');
      }),
      catchError((error) => {
        this.removeLoaderFromAPI(ctx, ApiActions.reviewBoardingPass, 'reviewBoardingPass');
        throw error;
      }),
    );
  }

  @Action(CreateRevenirTrip)
  createRevenirTrip(ctx: StateContext<RevenirStateModel>, action: CreateRevenirTrip) {
    const newPayload = createRevenirTripPayload(action?.payload?.formData);
    return this.revenirService.createRevenirTrip(newPayload).pipe(
      tap(() => {
        this.alertHandlerService.showAlertFn('success', 'Trip created successfully.');
      }),
      catchError((error) => {
        throw error;
      }),
    );
  }

  @Action(CreateRevenirSubjectToken)
  createSubjectToken() {
    return this.revenirService.createRevenirSubjectToken().pipe(
      map(() => {}),
      catchError((_err) => {
        throw _err;
      }),
    );
  }

  public getCountryName(countryCode: string) {
    return getRevenirCountryObject(countryCode, 'countryCode')?.countryName;
  }

  @Action(UploadPassport)
  async uploadPassport(ctx: StateContext<RevenirStateModel>, action: UploadPassport) {
    this.addLoaderInAPI(ctx, ApiActions.uploadPassport, 'uploadPassport');
    const passportUploadAPIRequest = await this.revenirService.uploadPassport(action.blobUrl, action.userId);

    const widgetProperties: BaseWidgetProperties = {
      urlToNavigate: 'passport-processing',
      zoneToNavigate: APP_ZONES.REVENIR_ZONE,
      textContent: '',
    };
    redirectToPage(this.router, widgetProperties);

    return passportUploadAPIRequest.pipe(
      tap(() => {
        this.removeLoaderFromAPI(ctx, ApiActions.uploadPassport, 'uploadPassport');

        widgetProperties['urlToNavigate'] = 'passport-success';
        redirectToPage(this.router, widgetProperties);
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.uploadPassport, 'uploadPassport');
        widgetProperties['urlToNavigate'] = 'passport-upload-failed';
        redirectToPage(this.router, widgetProperties);
        throw _err;
      }),
    );
  }

  @Action(GetRevenirSubjectStatus)
  getRevenirSubjectStatus(ctx: StateContext<RevenirStateModel>) {
    this.addLoaderInAPI(ctx, ApiActions.revenirKycStatus, 'revenirKycStatus');
    return this.revenirService.getRevenirSubjectStatus().pipe(
      map((res: RevenirSubjectStatusResponse) => {
        this.removeLoaderFromAPI(ctx, ApiActions.revenirKycStatus, 'revenirKycStatus');
        ctx.patchState({
          ...ctx.getState(),
          kyc_status: res.kyc_status,
        });
      }),
      catchError((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.revenirKycStatus, 'revenirKycStatus');
        throw _err;
      }),
    );
  }
}
