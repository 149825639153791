export enum BorderRadiusClasses {
  RADIUS_ONE = 'radius-1',
  RADIUS_TWO = 'radius-2',
  NONE = 'none',
}

export enum BorderStyles {
  SOLID = 'solid',
  DOTTED = 'dotted',
  DASHED = 'dashed',
}

export enum TextDecorationTypes {
  NONE = 'none',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKE_THROUGH = 'strike through',
}

export enum TypographyKeys {
  HEADING1 = 'h1',
  HEADING2 = 'h2',
  HEADING3 = 'h3',
  HEADING4 = 'h4',
  HEADING5 = 'h5',
  HEADING6 = 'h6',
  SUBTITLE1 = 'subtitle1',
  SUBTITLE2 = 'subtitle2',
  BODY1 = 'body1',
  BODY2 = 'body2',
  BUTTON_TEXT = 'buttonText',
  LABEL_TEXT = 'labelText',
}
