export const confirmBtnTemplate = `Confirm`;

export const beneficiaryDetailsTemplate = `<div class="w-100 h-100">
<div class='is-vertical-align' style='align-items: flex-start;'>
    <div class="ben-label">Full Name</div>
    <div class="ben-value">{{ beneficiaryDetails.name }}</div>
</div>
<hr class="my-4 mx-1">
<div class="bank-info">
    <div class='is-vertical-align' style='padding-right: 66px; justify-content: space-between;'>
        <div class='is-vertical-align align-left'>
            <div class="ben-label">Sort Code</div>
            <div class="ben-value">{{ addDashesToSortCode beneficiaryDetails.sortCode}}</div>
        </div>
    </div>
    <div class='is-vertical-align align-left'>
        <div class="ben-label" >Account Number</div>
        <div class="ben-value">{{ beneficiaryDetails.accountNumber }}</div>
    </div>
</div>
</div>`;

export const suggestedBankBeneficiaryDetailsTemplate = `<div class="w-100 h-100">
<div class='is-vertical-align' style='align-items: flex-start;'>
    <div class="ben-label">Full Name</div>
    <div class="ben-value">{{ beneficiaryDetails.name }}</div>
</div>
<hr class="my-4 mx-1">
<div class="bank-info">
    <div class='is-vertical-align' style='padding-right: 66px; justify-content: space-between;'>
        <div class='is-vertical-align align-left'>
            <div class="ben-label">Sort Code</div>
            <div class="ben-value">{{ addDashesToSortCode beneficiaryDetails.sortCode}}</div>
        </div>
    </div>
    <div class='is-vertical-align align-left'>
        <div class="ben-label" >Account Number</div>
        <div class="ben-value">{{ beneficiaryDetails.accountNumber }}</div>
    </div>
</div>
</div>`;

export const copEnteredDetails = `
<div class="w-100">
    <div class="font-weight-medium">
        <div class="is-left mb-3">
            <div class="is-vertical-align">
                <div class="typog-sm is-left" style="color: var(--typography-color-1)">
                    {{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name
                </div>
                <div class="typog-md is-left">
                    {{ beneficiaryDetails.name }}
                </div>
            </div> 
        </div>
        <div class="is-left mb-3">
            <div class="is-vertical-align">
                <div class="typog-sm" style="color: var(--typography-color-1)">
                    Account Number
                </div>
                <div class="typog-md is-left">
                    {{ beneficiaryDetails.accountNumber }}  
                </div>
            </div>
        </div>  
        <div class="is-left">
            <div class="is-vertical-align">
                <div class="typog-sm is-left" style="color: var(--typography-color-1)">
                    Sort Code
                </div>
            <div class="typog-md is-left">
                {{ addDashesToSortCode beneficiaryDetails.sortCode }}
            </div>
        </div>  
    </div>
</div>`;

export const copCloseMatchDetails = `<div class="w-100">
              <div class="is-center typog-md mb-2"><b>You entered:</b></div>
              <div class="font-weight-medium">
                <div class="is-left mb-3">
                <div class="mt-2">
                  {{{imageFromImageName 'icon-library/material' 'mat_error' '20px'}}}
                </div>
                <div class="is-vertical-align ml-2">
                  <div class="typog-sm is-left" style="color: var(--typography-color-1)">{{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name</div>
                  <div class="typog-md is-left">
                    {{ beneficiaryDetails.name }}
                  </div>
                </div> 
                </div>
                <div class="is-left mb-3">
                  <div class="mt-2">
                    {{{imageFromImageName 'icon-library/material' 'mat_success_check_circle_outline' '20px'}}}
                  </div>
                  <div class="is-vertical-align ml-2">
                    <div class="typog-sm" style="color: var(--typography-color-1)">Account Number</div>
                    <div class="typog-md is-left">
                      {{ beneficiaryDetails.accountNumber }}  
                    </div>
                  </div>
                </div>  
                <div class="is-left">
                  <div class="mt-2">
                    {{{imageFromImageName 'icon-library/material' 'mat_success_check_circle_outline' '20px'}}}
                  </div>
                  <div class="is-vertical-align ml-2">
                    <div class="typog-sm is-left" style="color: var(--typography-color-1)">Sort Code</div>
                    <div class="typog-md is-left">{{ addDashesToSortCode beneficiaryDetails.sortCode }}
                    </div>
                  </div>  
              </div>
            </div>`;

export const copCloseMatchReturnedBankName = `{{#if (eq copResponse.matchStatusCode 'name_matched')}}
              You submitted this beneficiary as a 
              {{#if (eq beneficiaryDetails.accountType 'personal')}}Personal{{else}}Business{{/if}} account, but their bank told us they are a {{#if (eq beneficiaryDetails.accountType 'personal')}}Business{{else}}Personal{{/if}} account.
            {{else}}
            <div class="w-100"><div class="is-center">Their bank told us:</div>
<div class="is-vertical-align align-items-flex-start">
<div class="typog-sm mt-2" style="color: var(--typography-color-1)">{{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name</div><div>{{ copResponse.name }}</div>
</div>
</div>
{{/if}}`;

export const copNoMatchDetails = `<div class="w-100">
              <div class="is-center typog-md mb-2"><b>You entered:</b></div>
              <div class="font-weight-medium">
                <div class="is-left mb-3">
 
                <div class="is-vertical-align">
                  <div class="typog-sm is-left" style="color: var(--typography-color-1)">{{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name</div>
                  <div class="typog-md is-left">
                    {{ beneficiaryDetails.name }}
                  </div>
                </div> 
                </div>
                <div class="is-left mb-3">
                  <div class="is-vertical-align">
                    <div class="typog-sm is-left" style="color: var(--typography-color-1)">Account Number</div>
                    <div class="typog-md is-left">
                      {{ beneficiaryDetails.accountNumber }}  
                    </div>
                  </div>
                </div>  
                <div class="is-left">
                  <div class="is-vertical-align">
                    <div class="typog-sm is-left" style="color: var(--typography-color-1)">Sort Code</div>
                    <div class="typog-md is-left">{{ addDashesToSortCode beneficiaryDetails.sortCode }}
                    </div>
                  </div>  
              </div>
            </div></div>`;
