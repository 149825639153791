import { ActivatedRoute, Router } from '@angular/router';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { Store } from '@ngxs/store';
import { take } from 'rxjs';
import { SetFormActionWithId } from '../../../actions/form-submission.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import {
    AddBeneficiaryRequest
} from '../../../services/account-service/account.type';
import { CopResponseState } from '../../../state/cop-response.state';
import { FormActionState } from '../../../state/form-submision.state';
import { addEditBeneficiary } from './add-beneficiary';

export function updateBeneficiaryFormData(
  store: Store,
  router: Router,
  accountService: AccountService,
  route: ActivatedRoute,
  alertHandlerService: AlertHandlerService,
) {
  //get correct bank name from state
  const suggestedName = store.selectSnapshot(CopResponseState.getCopResponseState).name;
  //decide which form state to update
  route.queryParams.pipe(take(1)).subscribe((params) => {
    const beneficiaryFlowParam = params['flow'];
    let formData;
    let formActionId;
    if (beneficiaryFlowParam === OtpParam.AddBeneficiary) {
      formData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_BENEFICIARY),
      )?.formData as AddBeneficiaryRequest;
      formActionId = FormActionTypeEnum.ADD_BENEFICIARY;
      formData.name = suggestedName;
    } else {
      formData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY),
      )?.formData;
      formActionId = FormActionTypeEnum.EDIT_BENEFICIARY;
      formData.payload.name = suggestedName;
    }
    //update the form state with the suggested name from the bank

    store.dispatch(new SetFormActionWithId({ type: '', formData }, formActionId));
    //continue to add/edit the beneficiary
    addEditBeneficiary(store, router, accountService, route, alertHandlerService);
  });
}
