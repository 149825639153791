import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BeneficiaryRequirementsResponse,
  PaginatedResponse,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { Observable, catchError, map, of, take, tap, throwError } from 'rxjs';
import {
  ClearBeneficiaryCache,
  GetBeneficiary,
  GetBeneficiaryRequiredFieldsAction,
  SetBeneficiaryLoadingStatus,
  SetSelectedBeneficiary,
  UpdateBeneficiary,
  UpdateSelectedBeneficiaryStateInfo,
} from '../actions/beneficiary.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';
import { GetBeneficiaryResponse, UpdateBeneficiaryRequest } from '../services/account-service/account.type';
import { formatNameAsUrl } from '../utils/zone-url.utils';
import { ApiActions, UpdateApiIsLoadingAction } from './api-loading.state';

export interface BeneficiaryStateModel {
  isLoading: boolean;
  items: GetBeneficiaryResponse[];
  totalPages: number;
  selectedBeneficiary?: string;
  totalResult?: number;
  pageLimit?: number;
  beneficiaryRequiredFields: BeneficiaryRequirementsResponse;
}

@State<BeneficiaryStateModel>({
  name: 'beneficiary',
  defaults: {
    isLoading: false,
    items: [],
    totalPages: 1,
    beneficiaryRequiredFields: {} as BeneficiaryRequirementsResponse,
  },
})
@Injectable()
export class BeneficiaryState {
  constructor(
    private accountService: AccountService,
    private alertHandlerService: AlertHandlerService,
    private store: Store,
    private router: Router,
  ) {}

  @Selector()
  static getBeneficiary(state: BeneficiaryStateModel): BeneficiaryStateModel {
    return state;
  }
  @Selector()
  static setSelectedBeneficiary(state: BeneficiaryStateModel) {
    return state;
  }
  @Selector()
  static getLoadingStatus(state: BeneficiaryStateModel) {
    return state.isLoading;
  }

  static getBeneficiaryRequiredFields() {
    return createSelector(
      [BeneficiaryState],
      (state: BeneficiaryStateModel): BeneficiaryRequirementsResponse => {
        return state.beneficiaryRequiredFields;
      },
    );
  }

  @Action(SetBeneficiaryLoadingStatus)
  setLoadingStatus(ctx: StateContext<BeneficiaryStateModel>, action: SetBeneficiaryLoadingStatus) {
    try {
      ctx.patchState({
        ...ctx.getState(),
        isLoading: action.isLoading,
      });
      return true;
    } catch (err) {
      return throwError(() => err);
    }
  }
  @Action(GetBeneficiary)
  fetchBeneficiary(ctx: StateContext<BeneficiaryStateModel>, action: GetBeneficiary) {
    try {
      if (action.isLoaderShown) {
        ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetBeneficiary' }));
        ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getBeneficiaries, true));
      }
      return this.accountService
        .getBeneficiary(action.page, action.size, action?.searchValue, action?.currency)
        .pipe(
          tap((beneficiaryList: PaginatedResponse<GetBeneficiaryResponse>) => {
            const prevData = ctx.getState().items;
            let beneficairyData;
            if (action.page === 1) {
              beneficairyData = beneficiaryList.result;
            } else {
              beneficairyData = prevData.concat(beneficiaryList.result);
            }

            ctx.setState({
              isLoading: false,
              items: beneficairyData,
              totalPages: beneficiaryList.totalPages ?? 1,
              totalResult: beneficiaryList.totalItems,
              pageLimit: Number(beneficiaryList.limit),
              beneficiaryRequiredFields: ctx.getState().beneficiaryRequiredFields,
            });

            ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetBeneficiary' }));
            ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getBeneficiaries, false));
          }),
          catchError<unknown, Observable<boolean>>((_err) => {
            ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetBeneficiary' }));
            ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getBeneficiaries, false));
            throw _err;
          }),
        );
    } catch (err) {
      ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetBeneficiary' }));
      ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getBeneficiaries, false));
      return throwError(() => err);
    }
  }
  @Action(SetSelectedBeneficiary)
  fetchSelectedBeneficiary(ctx: StateContext<BeneficiaryStateModel>, action: SetSelectedBeneficiary) {
    try {
      const beneficiaryData = ctx.getState();
      return ctx.setState({
        isLoading: false,
        items: beneficiaryData.items,
        selectedBeneficiary: action.payload,
        totalPages: 1,
        beneficiaryRequiredFields: beneficiaryData.beneficiaryRequiredFields,
      });
    } catch (err) {
      return throwError(() => err);
    }
  }
  @Action(UpdateSelectedBeneficiaryStateInfo)
  updateSelectedBeneficiaryStateInfo(
    ctx: StateContext<BeneficiaryStateModel>,
    action: UpdateSelectedBeneficiaryStateInfo,
  ) {
    try {
      const beneficiaryData = ctx.getState();
      const selectedBeneficiaryIndex = beneficiaryData.items.findIndex(
        (v) => v.id === action?.beneficiaryInfo.id,
      );
      if (selectedBeneficiaryIndex !== -1) {
        beneficiaryData.items[selectedBeneficiaryIndex] = action?.beneficiaryInfo;
      }
      return ctx.setState({ ...beneficiaryData });
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(UpdateBeneficiary)
  UpdateBeneficiary(
    ctx: StateContext<BeneficiaryStateModel>,
    action: { payload: UpdateBeneficiaryRequest; id: string },
  ) {
    this.accountService
      .updateBeneficiary(action.id, action.payload)
      .pipe(
        take(2),
        map((response) => {
          if (response) {
            this.store.dispatch(new UpdateSelectedBeneficiaryStateInfo(response));
            this.router.navigateByUrl(
              `/zones/${formatNameAsUrl(APP_ZONES.BENEFICIARY_ZONE)}/beneficiary-list`,
            );
            this.alertHandlerService.showAlertFn(
              'success',
              'The beneficiary has been successfully edited.',
              'Beneficiary Update Complete!',
            );
          }
        }),
        catchError((error) => {
          this.alertHandlerService.showAlertFn(
            'error',
            "There was an error editing the beneficiary's details. Try again.",
            "Failed to update beneficiary's details!",
          );
          console.error('Error While Editing Beneficiary:', error);
          return of(null);
        }),
      )
      .subscribe();
  }

  @Action(GetBeneficiaryRequiredFieldsAction)
  fetchBeneficiaryRequiredFields(
    ctx: StateContext<BeneficiaryStateModel>,
    action: GetBeneficiaryRequiredFieldsAction,
  ) {
    ctx.dispatch(
      new AddProgressBarStack({
        uniqueId: 'GetBeneficiaryRequiredFieldsAction',
      }),
    );
    ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getBeneficiaryRequiredFields, true));

    return this.accountService.getBeneficiaryRequirements(action.countryCode, action.currencyCode).pipe(
      tap((beneficiaryRequireFields) => {
        const beneficiaryData = ctx.getState();
        ctx.setState({
          ...beneficiaryData,
          beneficiaryRequiredFields: beneficiaryRequireFields,
        });

        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetBeneficiaryRequiredFieldsAction',
          }),
        );
        ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getBeneficiaryRequiredFields, false));
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetBeneficiaryRequiredFieldsAction',
          }),
        );
        ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getBeneficiaryRequiredFields, false));
        throw _err;
      }),
    );
  }

  @Action(ClearBeneficiaryCache)
  clearAccountCache(ctx: StateContext<BeneficiaryStateModel>) {
    ctx.patchState({ isLoading: false, items: [], totalPages: 1 });
  }
}
