import { ThemeConfig } from '../config.type';
import { Section, WorkflowConfig } from '../workflow.type';
import { WorkflowPageStateConfig, WorkflowWidgetConfig } from '../workflow.v2.type';

import _ from 'lodash';

export function removeDuplicateRoles(config: WorkflowConfig) {
  config.permissionSections.roles = _.uniqWith(
    config.permissionSections.roles,
    (role, othRole) => role.id === othRole.id,
  );
}

/**
  // Example usage
  const cssString = `
  background-color: #f0f0f0;
  font-size: 16px;
  margin-top: 10px;
  border: 1px solid black;
  `;
  const ngStyleObject = cssToNgStyle(cssString);
  console.log(ngStyleObject);
 */
export function cssToNgStyle(cssString: string): { [key: string]: string } {
  // Remove comments and normalize whitespace
  cssString = cssString.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '').trim();

  // Split the string into individual style declarations
  const declarations = cssString.split(';').filter((decl) => decl.trim() !== '');

  // Convert to object
  const styleObject: { [key: string]: string } = {};

  declarations.forEach((declaration) => {
    const [property, value] = declaration.split(':').map((str) => str.trim());
    if (property && value) {
      const camelCaseProp = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      styleObject[camelCaseProp] = value;
    }
  });

  return styleObject;
}

export function isWorkflowWidgetConfig(
  config: WorkflowWidgetConfig | Section,
): config is WorkflowWidgetConfig {
  return (
    (<WorkflowWidgetConfig>config)?.widgetTypeConfig !== undefined &&
    (<WorkflowWidgetConfig>config)?.widgetTypeConfig === 'new'
  );
}

export function isWorkflowPageStateConfig(
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any,
): config is WorkflowPageStateConfig {
  return (
    (<WorkflowPageStateConfig>config)?.pageStateTypeConfig !== undefined &&
    (<WorkflowPageStateConfig>config)?.pageStateTypeConfig === 'new'
  );
}

export const euCountriesList = [
  {
    currencyFlag: '',
    title: 'Select Country',
    key: '',
  },
  {
    currencyFlag: '<img src="assets/flags/fr.svg" width="24px">',
    title: 'France',
    key: 'france',
  },
  {
    currencyFlag: '<img src="assets/flags/it.svg" width="24px">',
    title: 'Italy',
    key: 'italy',
  },
  {
    currencyFlag: '<img src="assets/flags/pt.svg" width="24px">',
    title: 'Portugal',
    key: 'portugal',
  },
  {
    currencyFlag: '<img src="assets/flags/es.svg" width="24px">',
    title: 'Spain',
    key: 'spain',
  },
  {
    currencyFlag: '<img src="assets/flags/nl.svg" width="24px">',
    title: 'Netherlands',
    key: 'netherlands',
  },
  {
    currencyFlag: '<img src="assets/flags/be.svg" width="24px">',
    title: 'Belgium',
    key: 'belgium',
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hexToRgb(hex: string, type?: string): any {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hex = hex.replace(shorthandRegex, function (m: any, r: any, g: any, b: any) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  // to return the r,g,b values without the rgb() wrap
  if (result && type === 'withoutRgbWrapper') {
    return `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}`;
  }
  return result
    ? `rgb(${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)})`
    : `rgb(0 0 0)`;
}

export function rgbaStringToHex(rgba: string) {
  // Regular expression to match rgba or rgb strings
  const rgbaPattern = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*(0|0?\.\d+|1(\.0)?))?\)$/;
  const match = rgba.match(rgbaPattern);

  if (!match) {
    return rgba;
  }
  // Parse the rgba values from the match result
  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);
  const a = match[4] !== undefined ? parseFloat(match[4]) : 1; // default alpha to 1 if not provided
  // Ensure the values are within the proper range
  if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255 || a < 0 || a > 1) {
    throw new Error('RGBA values out of range.');
  }
  // Convert the r, g, b values to hex
  const red = r.toString(16).padStart(2, '0');
  const green = g.toString(16).padStart(2, '0');
  const blue = b.toString(16).padStart(2, '0');
  // Convert the alpha value to a percentage string
  let alpha = '';
  if (a !== 1) {
    alpha = ` ${(a * 100).toFixed(0)}%`;
  }
  // Combine the hex values
  return `#${red}${green}${blue} ${alpha}`;
}

export function buildThemeCssVarsObject(theme: ThemeConfig): {
  [key: string]: string;
} {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name: string, ...themeWithoutName } = theme.colour_palette;
  const output = {};
  const colorPrefixMap: { [key: string]: string } = {
    border_colors: 'border',
    typography: 'typography',
    alerts: 'alerts',
    palette: 'palette',
    neutrals: 'neutral',
  };
  let colorPrefix: string;
  Object.entries(themeWithoutName).forEach((t) => {
    colorPrefix = colorPrefixMap[t[0]];
    const colors = t[0] !== 'typography' ? t[1] : t[1]['palette'];
    for (let i = 0; i < Object.keys(colors).length; i++) {
      const colorKey = `--${colorPrefix}-color-${i}`;
      const rgbKey = `--${colorPrefix}-color-${i}-rgb`;

      const newVariable = mapToNewVariables[colorKey];
      if (newVariable) {
        Object.assign(output, { [newVariable]: colors[i.toString()] });
        Object.assign(output, {
          [`${newVariable}-rgb`]: hexToRgb(colors[i.toString()]),
        });
      }
      Object.assign(output, { [colorKey]: colors[i.toString()] });
      Object.assign(output, { [rgbKey]: hexToRgb(colors[i.toString()]) });
    }
  });
  Object.assign(output, {
    '--font-family': theme.fontFamily
      ? theme.fontFamily
      : theme.colour_palette.typography?.fontFamily
      ? theme.colour_palette.typography?.fontFamily
      : 'Mulish',
  });
  return output;
}

const mapToNewVariables: { [key: string]: string } = {
  '--border-color-0': '--border-color-active',
  '--border-color-1': '--border-color-inactive',
  '--border-color-2': '--border-color-disabled',
  '--typography-color-0': '--typography-color-primary',
  '--typohraphy-color-1': '--typography-color-secondary',
  '--typography-color-2': '--typography-color-disabled',
  '--alerts-color-0': '--alerts-color-error',
  '--alerts-color-1': '--alerts-color-success',
  '--alerts-color-2': '--alerts-color-info',
  '--alerts-color-3': '--alerts-color-warning',
  '--palette-color-0': '--palette-color-black',
  '--palette-color-1': '--palette-color-darkgrey',
  '--palette-color-2': '--palette-color-lightgrey',
  '--palette-color-3': '--palette-color-primary',
  '--palette-color-4': '--palette-color-secondary',
  '--palette-color-5': '--palette-color-tertiary',
  '--palette-color-6': '--palette-color-white',
};

export function getCssVariableValue(variableName: string): string {
  // Get the root element (document.documentElement)
  const root = document.documentElement;
  // Get the computed style of the root element
  const computedStyle = getComputedStyle(root);
  // Return the value of the CSS variable
  return computedStyle.getPropertyValue(variableName).trim();
}
