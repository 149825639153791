import { Injectable } from '@angular/core';
import { SetPaymentRequestSummary } from '@app/finxone-web-frontend/app/lib/actions/notification.action';
import { PaymentRequestDetailResponse } from '@finxone-platform/shared/utils';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap } from 'rxjs';
import {
  CreatePaymentRequest,
  PaymentRequestFailed,
  PaymentRequestSuccess,
  UpdatePaymentRequest,
  UpdatePaymentRequestFailed,
  UpdatePaymentRequestSuccess,
} from '../actions/payment-request.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PaymentStateModel {}

@State<PaymentStateModel>({
  name: 'paymentRequest',
  defaults: {},
})
@Injectable()
export class PaymentRequestState {
  constructor(private accountService: AccountService) {}

  @Action(CreatePaymentRequest)
  createPayment(ctx: StateContext<PaymentStateModel>, payload: CreatePaymentRequest) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CreatePaymentRequest' }));

    return this.accountService.makePaymentRequest(payload.paymentRequest).pipe(
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreatePaymentRequest' }));
        ctx.dispatch(new PaymentRequestSuccess());
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreatePaymentRequest' }));
        ctx.dispatch(new PaymentRequestFailed());
        throw _err;
      }),
    );
  }

  @Action(UpdatePaymentRequest)
  updatePaymentRequest(ctx: StateContext<PaymentStateModel>, payload: UpdatePaymentRequest) {
    const approvalStatus = payload.paymentRequest.approvedStatus;
    return this.accountService.updatePaymentRequest(payload.paymentRequest).pipe(
      tap((response: PaymentRequestDetailResponse) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'UpdatePaymentRequest' }));

        // update the summary data
        ctx.dispatch(
          new SetPaymentRequestSummary({
            ...response,
            amount: response.amount.toString(),
            approvedOrRejectedDate: response.approvedOrRejectedDate?.toString() || '',
            createdDate: response.createdDate?.toString() || '',
          }),
        );

        if (approvalStatus === 'approved') {
          ctx.dispatch(new UpdatePaymentRequestSuccess());
        }

        return response;
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'UpdatePaymentRequest' }));
        ctx.dispatch(new UpdatePaymentRequestFailed());
        throw _err;
      }),
    );
  }
}
